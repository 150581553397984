<template lang="pug">
.pillboxPoint(:class="modifiers")
  slot
  .pillboxPoint-number(:class="modifiers") {{number}}
</template>

<script>
export default {
  props: {
    number: Number,
    invert: Boolean,
  },
  computed: {
    modifiers(){
      return {_invert: this.invert}
    }
  }
}

</script>

<style lang="scss">
.pillboxPoint {
  position: absolute;
  width: 160px;
  background: white;
  z-index: 1;
  box-shadow: 0px 4px 15px rgba(22, 20, 44, 0.06);
  border-radius: 18px 6px 18px 18px;
  padding: 10px;
  padding-top: 40px;
  font-size: 12px;
  line-height: 16px;
  color: $grey-2;
  &._invert {
    border-radius: 6px 18px 18px 18px;
  }
}
.pillboxPoint-number {
  background: #7C74E9;
  border-radius: 18px 2px 18px 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  &._invert {
    border-radius: 2px 18px 18px 18px;
    right: none;
    left: 10px
  }
}

</style>