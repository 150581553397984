<template lang="pug">
.pillboxPage.layout
  .pillboxPage-content
    .pillboxPage-title.h1 Таблетница
    .pillboxPage-tip
      span Здесь могла бы быть Таблетница, но вы открыли это приложение не с Андроида
      .pillboxPage-emoji 🙂
    .pillboxPage-text А если серьезно, то таблетница доступна в Android-версии приложения LabStory.<br> Мы сделали удобную виртуальную таблетницу, где можно отслеживать все приемы препаратов: от БАДов до лекарств и даже процедур. 
    .pillboxPage-links
      img(src="@/assets/qrcode.png").pillboxPage-qrCode
      BaseBtn(:href="$options.urlToGooglePlay").pillboxPage-download Скачать LabStory App
      a(:href="$options.urlToGooglePlay" target="_blank").pillboxPage-googlePlay: img(src="@/assets/google-play.png")
      .pillboxPage-linksText Наведите камеру на QR-код, чтобы перейти к приложению в Play Market
    portal(to="pillboxPage-mobileSubtext" :disabled="isDesktop"): .pillboxPage-subtext Вся эта красота сейчас доступна только в Android-версии приложения. Если вам было бы удобно пользоваться таблетницей с веб-версии, напишите нам об этом — чтобы мы могли рассмотреть её появление и здесь.
  .pillboxPage-preview
    .pillboxPage-previewContainer._samsung
      img(src="@/assets/samsung.png").pillboxPage-samsung
      img(src="@/assets/samsung-m.png").pillboxPage-samsungMobile
      PillboxPoint(:number="1" :invert="!isDesktop").pillboxPage-pillboxPoint._1 Отслеживайте длительность курса и количество приемов. Вы можете ставить курсы на паузы и продолжать в любое время
      portal(to="pillboxPage-mobilePillboxPoint" :disabled="isDesktop"): PillboxPoint(:number="2" :invert="!isDesktop").pillboxPage-pillboxPoint._2 Создавайте сколько угодно курсов на платном тарифе. На бесплатном тарифе можно создать один курс — просто, чтобы попробовать
    .pillboxPage-previewContainer._mobile
      img(src="@/assets/preview-m.png").pillboxPage-screenShot
      portal-target(name="pillboxPage-mobilePillboxPoint")
    .pillboxPage-previewContainer
      img(src="@/assets/preview1.png").pillboxPage-screenShot
      PillboxPoint(:number="3" invert).pillboxPage-pillboxPoint._3 Добавляйте разные курсы приемов препаратов или расписание процедур
    .pillboxPage-previewContainer
      img(src="@/assets/preview2.png").pillboxPage-screenShot
      PillboxPoint(:number="4" invert).pillboxPage-pillboxPoint._4 Добавляйте фото препаратов, чтобы не забыть, что именно вам нужно купить, или делиться этим с врачом
    .pillboxPage-previewContainer
      img(src="@/assets/preview3.png").pillboxPage-screenShot
      PillboxPoint(:number="5" invert).pillboxPage-pillboxPoint._5 Получайте напоминания о времени приема и отмечайте статус прямо в уведомлении без перехода в приложение 
  portal-target(name="pillboxPage-mobileSubtext")
</template>

<script>
import PillboxPoint from "./PillboxPoint.vue";
export default {
  urlToGooglePlay: 'https://play.google.com/store/apps/details?id=ru.labstori.android',
  components: {
    PillboxPoint
  },
  computed: {
    // todo
    isDesktop(){
      return this.$q.screen.width > 1367
    }
  }
}
</script>

<style lang="scss">
.pillboxPage {
  display: flex;
  gap: 80px;
  @include media-down($breakpoint-lg){
    gap: 0px;
    flex-direction: column;
  }
}

.pillboxPage-content{
  // margin-right: 80px;
}

.pillboxPage-title {
  margin-bottom: 18px;
  @include media-down($breakpoint-lg){
    margin-bottom: 20px;
  }
}

.pillboxPage-tip {
  background: $light-stroke;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  gap: 16px;
  align-items: center;
  color: $grey-2;
  @include media-up($breakpoint-lg){
    max-width: 352px;
  }
  @include media-down($breakpoint-lg){
    font-size: 14px;
    width: 100%;
  }
  margin-bottom: 20px;
}

.pillboxPage-qrCode {
  width: 110px;
  height: 110px;
  grid-row: 1 / 3;
  @include media-down($breakpoint-lg){
    display: none
  }
}

.pillboxPage-download {
  @include media-up($breakpoint-lg){
    display: none
  }
}

.pillboxPage-googlePlay {
  height: 42px;
  @include media-down($breakpoint-lg){
    height: 50px;
  }
  img {
    height: 42px;
    @include media-down($breakpoint-lg){
      height: 50px;
    }
  }
}

.pillboxPage-emoji  {
  font-size: 24px;
  line-height: 20px;
}

.pillboxPage-text {
  font-size: 16px;
  line-height: 22px;
  color: $grey-2;
  margin-bottom: 40px;
  @include media-up($breakpoint-lg){
    max-width: 400px;
  }
  @include media-down($breakpoint-lg){
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.pillboxPage-links {
  display: grid;
  max-width: 339px;
  grid-template-columns: auto auto;
  gap: 14px 20px;
  margin-bottom: 126px;
  @include media-down($breakpoint-lg){
    max-width: none;
    margin-bottom: 60px;
  }
}

.pillboxPage-linksText {
  font-size: 12px;
  line-height: 15px;
  color: $grey-4;
  @include media-down($breakpoint-lg){
    display: none
  }
}

.pillboxPage-subtext {
  max-width: 526px;
  font-size: 14px;
  line-height: 20px;
  color: $grey-3;
  @include media-down($breakpoint-lg){
    margin-top: 30px;
  }
}

.pillboxPage-preview {
  display: grid;
  grid-template-columns: auto auto;
  gap: 18px 11px;
  @include media-down($breakpoint-lg){
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.pillboxPage-previewContainer {
  position: relative;

  &._samsung {
    grid-row: 1 / 4;
  }
  &._mobile {
    @include media-up($breakpoint-lg){
      display: none;
    }
  }
}
.pillboxPage-pillboxPoint{
  &._1 {
    top: 62px;
    left:-120px
  }
  &._2 {
    top: 308px;
    left:-120px
  }
  &._3 {
    top: 72px;
    right: -143px;
  }
  &._4 {
    top: 24px;
    right: -143px;
  }
  &._5 {
    top: 31px;
    right: -143px;
  }

  @include media-down($breakpoint-lg){
    right: 0 !important;
    top: 20px !important;
    left: auto !important;
  }
}

.pillboxPage-samsung {
  width: 264px;
  @include media-down($breakpoint-lg){
    display: none;
  }
}
.pillboxPage-samsungMobile {
  width: 300px;
  @include media-up($breakpoint-lg){
    display: none;
  }
}

.pillboxPage-screenShot{
  box-shadow: 0px 2.78717px 10.4519px rgba(22, 20, 44, 0.06);
  border-radius: 15px;
  width: 239px;
  @include media-down($breakpoint-lg){
    width: 300px;
  }
}

</style>